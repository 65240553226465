const getCurrentUserApi = '/users/get-current'
const setDefaultAddressApi = '/users/set-default-address'
const uploadAvatarApi = '/users/upload-avatar-for-current'
const updateCurrentUserApi = '/users/update-current'
const changePasswordApi = '/users/change-password'

export {
  getCurrentUserApi,
  setDefaultAddressApi,
  uploadAvatarApi,
  updateCurrentUserApi,
  changePasswordApi
}
