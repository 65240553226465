const signUpApi = '/auth/sign-up'
const signInApi = '/auth/sign-in'
const signOutApi = '/auth/sign-out'
const verifyAccountApi = '/auth/verify-account'
const forgotPasswordApi = '/auth/forgot-password'
const verifyPasswordResetOtpApi = '/auth/verify-password-reset-otp'
const resetPasswordApi = '/auth/reset-password'
const resendPasswordResetOtpApi = '/auth/resend-password-reset-otp'
const checkSignInStatusApi = '/auth/sign-in-status'
const refreshTokenApi = '/auth/refresh-token'
const signInWithGoogleApi = '/auth/sign-in-with-google'

export {
  signUpApi,
  signInApi,
  signOutApi,
  verifyAccountApi,
  forgotPasswordApi,
  verifyPasswordResetOtpApi,
  resetPasswordApi,
  resendPasswordResetOtpApi,
  checkSignInStatusApi,
  refreshTokenApi,
  signInWithGoogleApi
}
